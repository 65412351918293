<template>
  <div class="edit-group">
    <my-dialog :show="show" @close="close" v-if="originalRecord && record" max-width="800px">
      <div class="card">
        <div class="card__header">
          <div class="card__title">{{__('editShipClass')}}</div>
          <div class="card__button" @click="close" v-slashes>
            <img src="https://cdn.hyperion-corporation.de/ui/svgs/cross.svg" alt="x"/>
          </div>
        </div>
        <div class="card__content" v-if="record">
          <progress-linear v-if="loading"/>
          <div class="layout layout--wrap">
            <div class="flex xs12 sm6">
              <div class="layout layout--wrap">
                <div class="flex xs12 mt">
                  <text-field
                          v-model="record.name"
                          :label="__('entity.common.name')"
                          :error-messages="errors.name"
                          maxlength="50"
                          :prepend-icon="isChanged('name') ? 'pencil' : null"
                          @input="registerChange('name')"
                  />
                </div>
                <div class="flex xs12">
                  <text-field
                      v-model="record.color"
                      :label="__('color')"
                      :error-messages="errors.icon"
                      :prepend-icon="isChanged('color') ? 'pencil' : null"
                      @input="registerChange('color')"
                  />
                </div>
                <div class="flex xs12">
                  <text-area
                      v-model="record.description"
                      :label="__('entity.common.description')"
                      :type="'number'"
                      :error-messages="errors.description"
                      :prepend-icon="isChanged('description') ? 'pencil' : null"
                      @input="registerChange('description')"
                  />
                </div>
                <div class="flex xs6 mt">
                  <div class="btn btn--success" :loading="loading" :disabled="changedProperties.length === 0"
                       @click="update" v-slashes>{{ __('common.save_changes') }}
                  </div>
                </div>
                <div class="flex xs6 mt">
                  <div class="btn btn--primary" @click="close" v-slashes>{{ __('common.close') }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </my-dialog>
  </div>
</template>

<script>
  import { localizationMixin } from '../../../mixins/localization';
  import {editFormMixin} from "../../../mixins/editForm";

  export default {
    mixins: [localizationMixin, editFormMixin],
    data: () => ({
      endpoint: '/ingame/expo/shipclass',
      emptyRecord: {
        id: null,
        name: '',
        description: '',
        color: '',
        createdAt: '---',
        updatedAt: '---'
      },
    }),
  }
</script>