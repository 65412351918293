<template>
  <div class="groups view">
    <app-header :title="__('shipClasses')"/>
    <progress-linear v-if="loading" :indeterminate="true"/>
    <div class="control-bar" :class="{'control-bar--disabled': loading}">
      <div class="control-bar__info">
        <div class="control-bar__info-title">{{__('common.total')}}:</div>
        <div class="control-bar__info-value">{{ pagination.total }}</div>
      </div>
      <text-field
          class="control-bar__search"
          v-model="searchTerm"
          :label="__('common.search_term')"
          max-length="50"
          :hide-details="true"
          :clearable="true"
          @keyup:enter="refreshDelayed(100)"
          @click:clear="refreshDelayed(100)"
      />
      <div class="control-bar__buttons">
        <div class="control-bar__button" :title="__('common.refresh')" @click="refresh" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/refresh.svg"
               alt="refresh"/>
        </div>
        <div class="control-bar__button" :title="__('common.add')" @click="addShipClass" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/add.svg"
               alt="add"/>
        </div>
      </div>
    </div>
    <div class="scroll-horizontal-overflow">
      <pagination
          class="mt"
          :count-pages="pagination.pages"
          :total-visible="10"
          :page="pagination.page"
          :disabled="loading"
          @paginate="paginate"
      />
      <data-table
          :records="shipClasses"
          :fields="dataTableFields"
          :record-actions="recordActions"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          :loading="loading"
          @editShipClass="editShipClass"
          @sort="sort"
          @deleteRecord="deleteRecord"
      />
      <pagination
          class="mt"
          :count-pages="pagination.pages"
          :total-visible="10"
          :page="pagination.page"
          :disabled="loading"
          @paginate="paginate"
      />
    </div>
    <edit-ship-class ref="editShipClass" @updated="refresh" @created="editShipClass"/>
  </div>
</template>

<script>
  import Swal from 'sweetalert2';
  import apiSecured from '../../../api/secured';
  import DataTable from '../../../components/DataTable';
  import AppHeader from '../../../components/AppHeader';
  import EditShipClass from "../../../components/Ingame/Expo/EditShipClass";
  import { notificationsMixin } from '../../../mixins/notifications';
  import { localizationMixin } from '../../../mixins/localization';
  import { paginationMixin } from "../../../mixins/pagination";
  import { pageTitleMixin } from "../../../mixins/pageTitle";

  export default {
    mixins: [notificationsMixin, localizationMixin, paginationMixin, pageTitleMixin],
    components: {
      EditShipClass,
      appHeader: AppHeader,
      dataTable: DataTable,
      editShipClass: EditShipClass
    },
    data: () => ({
      loading: false,
      shipClasses: [],
      dataTableFields: [],
      recordActions: [],
    }),
    methods: {
      refreshDelayed(delay) {
        setTimeout(this.refresh, delay);
      },
      refresh() {
        this.loading = true;
        let params = this.getPaginationParams();
        this.$router.push({ name: this.$route.name, query: params }).catch(err => {
        });
        apiSecured.get('/ingame/expo/shipclass', { params }).then(async (res) => {
          this.shipClasses = res.data;
          this.updatePagination(res);
          this.loading = false;
          this.showInfoNotification(this.__('common.data_loaded'));
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error)
        });
      },
      addShipClass() {
        this.$refs.editShipClass.showCreate();
      },
      editShipClass(shipClass) {
        this.$refs.editShipClass.showEdit(shipClass);
      },
      deleteRecord(record) {
        Swal.fire({
          title: this.__('common.are_you_sure'),
          text: this.__('common.do_you_want_to_delete'),
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: this.__('common.yes_delete_it')
        }).then((result) => {
          if (result.value) {
            this.loading = true;
            let endpoint = '/ingame/expo/shipclass/' + record.id;
            apiSecured.delete(endpoint).then((res) => {
              this.loading = false;
              this.showSuccessNotification(this.__('common.deleted'));
              this.refresh();
            }).catch((error) => {
              this.loading = false;
              this.showErrorNotifications(error);
              this.refresh();
            });
          }
        });
      },
    },
    created() {
      this.setPageTitle(this.__('shipClasses'))
      this.dataTableFields = [
        { name: "id", title: this.__('entity.common.id'), type: "default" },
        { name: "name", title: this.__('entity.common.title'), type: "default" },
        { name: "color", title: this.__('color'), type: "default" },
        { name: "description", title: this.__('entity.common.description'), type: "default" },
        { name: "createdAt", title: this.__('entity.common.created_at'), type: "datetime" },
        { name: "updatedAt", title: this.__('entity.common.updated_at'), type: "datetime" }
      ];
      this.recordActions = [
        { title: this.__('component.auth.edit_group.title'), icon: 'pencil', event: 'editShipClass' },
        { title: this.__('view.ingame.groups.delete_group'), icon: 'bin', event: 'deleteRecord', restrictRoles: [] }
      ];
      this.refresh();
    }
  }
</script>
